import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit{
  constructor(private title: Title, private meta: Meta) {}
  ngOnInit(): void {
    this.title.setTitle(environment.POSTHTML_TITLE); 
    this.updateFavicon();
    //Facebook Meta Tags
    this.meta.addTag({ name: 'description', content: environment.POSTHTML_DESCRIPTION });
    this.meta.addTag({ name: 'og:url', content: 'https://'+environment.POSTHTML_DOMAIN });
    this.meta.addTag({ name: 'og:title', content: environment.POSTHTML_TITLE });
    this.meta.addTag({ name: 'og:description', content: environment.POSTHTML_DESCRIPTION });
    this.meta.addTag({ name: 'og:image', content: 'https://'+environment.POSTHTML_DOMAIN+'/open-graph.png' });

    //Twitter Meta Tags
    this.meta.addTag({ name: 'twitter:domain', content: environment.POSTHTML_DOMAIN });
    this.meta.addTag({ name: 'twitter:url', content: 'https://'+environment.POSTHTML_DOMAIN });
    this.meta.addTag({ name: 'twitter:title', content: environment.POSTHTML_TITLE });
    this.meta.addTag({ name: 'twitter:description', content: environment.POSTHTML_DESCRIPTION });
    this.meta.addTag({ name: 'twitter:image', content: 'https://'+environment.POSTHTML_DOMAIN+'/open-graph.png' });
    
  }

  clientId: string = environment.APP_CLIENTID

  updateFavicon(): void {
    const link: any = document.getElementById('favIcon');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = `./brand/${environment.brand}/${environment.brand}-favicon.ico`;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}
